import React, { useState, useEffect } from 'react'
import DigitalEconomy from '../images/digital-economy.png'
import ImageSlider from './ImageSlider'
import ProductSlider from './ProductSlider'
import ServiceSlider from './ServiceSlider'
import PartnerSlider from './PartnerSlider'
import Testimonials from './Testimonials'
import CategoryStats from './CategoryStats'
import PopNotice from './Notice/PopNotice'
import Aos from 'aos'

import '../css/homepage.css'
import 'aos/dist/aos.css'

const Homepage = () => {

    document.title = "Oxpan Microsys | Home"

    const [timedPopup, setTimedPopup] = useState(false)

    useEffect(() => {

        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        });

        setTimeout(() => {
            setTimedPopup(false);
        }, 1000);

        setTimeout(() => {
            setTimedPopup(false);
        }, 15000);

    }, [])

    return (

        <>

            <div className="notice-popup-section">
                <PopNotice trigger={timedPopup} setTrigger={setTimedPopup} />
            </div>

            <div className="slider-1">
                <ImageSlider />
            </div>

            <div className="category" data-aos="fade-down">
                <CategoryStats />
            </div>

            <div className="section-1" data-aos="fade-right">
                <div className="left">
                    <div className="banner-name">
                        <h2>
                            <span>OXPAN SOFTWARE</span>
                            <br />
                            <text>Co&#8722;operative </text>
                            <text2>BANKING Simplified</text2>
                        </h2>
                    </div>
                    <div className="divider">
                        <hr />
                    </div>
                    <div className="vision">
                        <h6>OXPAN Microsys envisions providing Cooperator software and services that simplify collaboration, increase productivity, and foster teamwork among businesses. Their all-in-one platform integrates with existing business systems and tools, allowing teams to communicate, share files, assign tasks, and track progress in real-time. OXPAN Microsys will prioritize a customer-centric approach to support and training, enabling users to leverage the full capabilities of Cooperator. The ultimate goal is to empower businesses to work together seamlessly, leading to increased efficiency, innovation, and success.</h6>
                    </div>
                </div>
                <div className="img" data-aos="fade-right">
                    <img src={DigitalEconomy} alt="Index" className="hero-img" />
                    {/* <h5>HELLO</h5> */}
                </div>
            </div>

            <div className="section-2" data-aos="fade-right">
                {/* <HomeProducts /> */}
                <ProductSlider />
                {/* <Suspense fallback={<div>Please wait.....</div>}><ProductSlider /></Suspense> */}
            </div>

            <div className="section-3" data-aos="fade-right">
                <ServiceSlider />
                {/* <Suspense fallback={<div>Please wait.....</div>}><ServiceSlider /></Suspense> */}
            </div>

            <div className="section-4" data-aos="fade-right">
                <PartnerSlider />
                {/* <Suspense fallback={<div>Please wait.....</div>}><PartnerSlider /></Suspense> */}
            </div>

            <div className="section-5" data-aos="fade-right">
                <Testimonials />
                {/* <Suspense fallback={<div>Please wait.....</div>}><Testimonials /></Suspense> */}
            </div>

        </>

    )
}

export default Homepage