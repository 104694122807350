// import React, { useState, useEffect } from 'react'
import './css/App.css'
import { Route, Routes } from 'react-router-dom'
import Homepage from './pages/Homepage'
import GoldPlus from './pages/Products/GoldPlus'
import TopBar from './pages/Navigation/TopBar'
import FixedSocial from './pages/Navigation/FixedSocial'
import Footer from './pages/Footer/Footer'
import NotFound from "./images/error-404.png"
import Dugdha from './pages/Products/Dugdha'
import AllMart from './pages/Products/AllMart'
import CoopMart from './pages/Products/CoopMart'
import Micos from './pages/Products/Micos'
import DesktopSoftwareDev from './pages/Services/DesktopSoftwareDev'
import CloudService from './pages/Services/CloudService'
import SmsService from './pages/Services/SmsService'
import WebHosting from './pages/Services/WebHosting'
import AndroidAppDev from './pages/Services/AndroidAppDev'
import WebsiteDevelopment from './pages/Services/WebsiteDevelopment'
import AboutUs from './pages/Company/AboutUs'
import FAQs from './pages/Company/FAQs'
import Career from './pages/Company/Career'
import Feedback from './pages/Company/Feedback'
import ContactUs from './pages/Contact/ContactUs'
import MobileBanking from './pages/Services/MobileBanking'
import CopoNext from './pages/Products/CopoNext'
import Bancos from './pages/Products/Bancos'
import SoftwareManual from './pages/Download/SoftwareManual'
import SoftwareDemo from './pages/Download/SoftwareDemo'
import CollectionApp from './pages/Download/CollectionApp'
import DownloadForms from './pages/Download/DownloadForms'
import Offers from './pages/Company/Offers'
// import PopNotice from './pages/Notice/PopNotice'

const App = () => {

  // const [timedPopup, setTimedPopup] = useState(false)

  // useEffect(() => {

  //   setTimeout(() => {
  //     setTimedPopup(true);
  //   }, 1000);

  // }, [])

  return (

    <>

      <div className="main-body-image">

        {/* <div className="notice-popup-section">
          <PopNotice trigger={timedPopup} setTrigger={setTimedPopup} />
        </div> */}

        <div className="header-top">
          <TopBar />
          {/* <TopNavbar /> */}
        </div>

        <div className="fixedsocialbar">
          <FixedSocial />
        </div>

        <Routes>

          <Route exact path="/" element={<Homepage />} />

          {/* Product Routes */}

          <Route path="/products/goldplus" element={<GoldPlus />} />
          <Route path="/products/dugdha" element={<Dugdha />} />
          <Route path="/products/allmart" element={<AllMart />} />
          <Route path="/products/coopmart" element={<CoopMart />} />
          <Route path="/products/micos" element={<Micos />} />
          <Route path="/products/coponext" element={<CopoNext />} />
          <Route path="/products/bancos" element={<Bancos />} />

          {/* Services Route */}

          <Route path="/services/desktopsoftware" element={<DesktopSoftwareDev />} />
          <Route path="/services/cloudservice" element={<CloudService />} />
          <Route path="/services/smsservice" element={<SmsService />} />
          <Route path="/services/mobilebanking" element={<MobileBanking />} />
          <Route path="/services/webhosting" element={<WebHosting />} />
          <Route path="/services/androidappdev" element={<AndroidAppDev />} />
          <Route path="/services/websitedevelopment" element={<WebsiteDevelopment />} />

          {/* Company Route */}

          <Route path="/company/aboutus" element={<AboutUs />} />
          <Route path="/company/career" element={<Career />} />
          <Route path="/company/feedback" element={<Feedback />} />
          <Route path="/company/faqs" element={<FAQs />} />
          <Route path='/company/offers' element={<Offers />} />

          {/* Download Route */}

          <Route path="/downloads/softwaremanual" element={<SoftwareManual />} />
          <Route path="/downloads/softwaredemo" element={<SoftwareDemo />} />
          <Route path="/downloads/collectionapp" element={<CollectionApp />} />
          <Route path="/downloads/forms" element={<DownloadForms />} />

          {/* Contact Us Route */}

          <Route path="/contactus" element={<ContactUs />} />

          <Route path='*' element={<img src={NotFound} alt="ERROR" width="100%" />} />

        </Routes>

        <div>
          <Footer />
        </div>

      </div>

    </>

  )
}

export default App