import React from 'react'
import '../../css/header.css'

const Header = () => {

    return (

        <>

            <div className="header">
                <div>
                    <i className='bi bi-envelope-at-fill' />
                    <a href="mailto:oxpanmicrosys@gmail.com">oxpanmicrosys@gmail.com</a>
                </div>
                <div className="contact">
                    <i className='bi bi-telephone-fill' />
                    <span>01-5905286, 9801906572</span>
                </div>
            </div>

            <div className="hr">
                <hr />
            </div>

        </>

    )
}

export default Header