import React from 'react'
import Facebook from "../../images/social/fb.png"
import Instagram from "../../images/social/insta.webp"
import Twitter from '../../images/social/twitter.png'
import Linkedin from '../../images/social/linkedin.jpg'
import "../../css/fixedsocial.css"

const FixedSocial = () => {

    return (

        <div className='fixed-social-links'>
            <div className='social-links-bg'>
                <a href="https://www.facebook.com/profile.php?id=61562347250339" target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook" /></a>
            </div>
            <div className='social-links-bg'>
                <a href="/#"><img src={Instagram} alt="instagram" /></a>
            </div>
            <div className='social-links-bg'>
                <a href="/#"><img src={Twitter} alt="twitter" /></a>
            </div>
            <div className='social-links-bg'>
                <a href="https://www.linkedin.com/in/oxpan-microsys-3879741b6/" target="_blank" rel="noreferrer"><img src={Linkedin} alt="linkedin" /></a>
            </div>
        </div>

    )
}

export default FixedSocial