import React from 'react'
import "../../css/popnotice.css"
import NoticeImg1 from "../../images/notice/bancos-advertise.jpg"

const PopNotice = (props) => {

    const Notice = [
        {
            subject: "Oxpan Presents New Software (BANCOS)",
            content: "",
            noticeImg: NoticeImg1,
            path: "/products/bancos"
        }
    ]

    return (props.trigger) ? (

        <>

            <div className='container-notice'>
                <div className="pop-section">

                    {/* <div className="pop-head">

    <h3 className="pop-notice">सूचना सूचना सूचना</h3>
    <button className="close-btn" onClick={() => props.setTrigger(false)}>
        <i className="bi bi-x-lg cross" />
    </button>

</div> */}

                    <div className="pop-body">

                        {/* <div className="close"> */}
                        <button className="close" onClick={() => props.setTrigger(false)}>
                            <i className="bi bi-x-lg cross" />
                        </button>
                        {/* </div> */}

                        {Notice.map((item) => {
                            return (
                                <>
                                    <h2 key={item.subject}>{item.subject}</h2>
                                    <p>{item.content}</p>
                                    <a href={item.path}>
                                        <img src={item.noticeImg} alt="Notice" className="notice-img" />
                                    </a>
                                </>
                            )
                        })}

                    </div>

                </div>
            </div>

        </>

    ) : "";
}

export default PopNotice