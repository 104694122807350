export const navItems = [
    {
        id: "1",
        title: "HOME",
        path: "/",
        cName: "nav-item",
    },
    {
        id: "2",
        title: "PRODUCTS",
        path: "/products",
        cName: "nav-item",
    },
    {
        id: "3",
        title: "SERVICES",
        path: "/services",
        cName: "nav-item",
    },
    {
        id: "4",
        title: "DOWNLOAD",
        path: "/download",
        cName: "nav-item",
    },
    {
        id: "5",
        title: "COMPANY",
        path: "/company",
        cName: "nav-item",
    },
    {
        id: "6",
        title: "CONTACT US",
        path: "/contactus",
        cName: "nav-item",
    },
];

export const productsDropdown = [
    {
        id: "1",
        title: "OXPAN Cooperator Gold Plus",
        path: "/products/goldplus",
        cName: "link-item",
    },
    {
        id: "2",
        title: "OXPAN COPONEXT",
        path: "/products/coponext",
        cName: "link-item",
    },
    {
        id: "3",
        title: "OXPAN BANCOS",
        path: "/products/bancos",
        cName: "link-item",
    },
    {
        id: "4",
        title: "OXPAN Cooperator Dugdha",
        path: "/products/dugdha",
        cName: "link-item",
    },
    {
        id: "5",
        title: "OXPAN AllMart",
        path: "/products/allmart",
        cName: "link-item",
    },
    {
        id: "6",
        title: "OXPAN CoopMart",
        path: "/products/coopmart",
        cName: "link-item",
    },
    {
        id: "7",
        title: "OXPAN MiCOS",
        path: "/products/micos",
        cName: "link-item",
    },
];

export const servicesDropdown = [
    {
        id: "1",
        title: "Desktop Software",
        path: "/services/desktopsoftware",
        cName: "link-item",
    },
    {
        id: "2",
        title: "Cloud Service",
        path: "/services/cloudservice",
        cName: "link-item",
    },
    {
        id: "3",
        title: "SMS Service",
        path: "/services/smsservice",
        cName: "link-item",
    },
    {
        id: "4",
        title: "Mobile Banking",
        path: "/services/mobilebanking",
        cName: "link-item",
    },
    {
        id: "5",
        title: "Website Development",
        path: "/services/websitedevelopment",
        cName: "link-item",
    },
    {
        id: "6",
        title: "Android App Development",
        path: "/services/androidappdev",
        cName: "link-item",
    },
    {
        id: "7",
        title: "Web Hosting",
        path: "/services/webhosting",
        cName: "link-item",
    },

];

export const downloadDropdown = [
    {
        id: "1",
        title: "Software Manual",
        path: "/downloads/softwaremanual",
        cName: "link-item",
    },
    {
        id: "2",
        title: "Software Demo",
        path: "/downloads/softwaredemo",
        cName: "link-item",
    },
    {
        id: "3",
        title: "Collection App",
        path: "/downloads/collectionapp",
        cName: "link-item",
    },
    {
        id: "4",
        title: "Forms",
        path: "/downloads/forms",
        cName: "link-item",
    },
];

export const companyDropdown = [
    {
        id: "1",
        title: "About Us",
        path: "/company/aboutus",
        cName: "link-item",
    },
    {
        id: "2",
        title: "Career",
        path: "/company/career",
        cName: "link-item",
    },
    {
        id: "3",
        title: "Offers",
        path: "/company/offers",
        cName: "link-item",
    },
    {
        id: "4",
        title: "Feedback",
        path: "/company/feedback",
        cName: "link-item",
    },
    {
        id: "5",
        title: "FAQs",
        path: "/company/faqs",
        cName: "link-item",
    },
];
