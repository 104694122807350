import React from 'react'
import Adv1 from '../Advertisement/Adv1'

const Offers = () => {
    return (
        <>
            <div className='advertisement'>
                <Adv1 />
            </div>
        </>
    )
}

export default Offers